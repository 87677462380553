<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }} <span v-if="market" class="small" style="color:black">[{{market}}]</span>
    </div>
    <CRow class="align-items-center">
      <CCol>
        <div class="form-group">
          <LabelHelp
            :required="true"
            :header="
              $t('producer.products.form.origin.sectionOrigin.variants.header')
            "
            :content="
              $t(
                'producer.products.form.origin.sectionOrigin.variants.description'
              )
            "
          >
            {{
              $t("producer.products.form.origin.sectionOrigin.variants.title")
            }}
          </LabelHelp>
          <UiCInputRadioGroup
            :options="productionTypeOptions"
            :checked="variant.production_type_id"
            @update:checked="onVariantProductionTypeChange(variant, $event)"
            custom
            inline
          />
          <small
            class="text-danger"
            v-if="!variant.isValid('production_type_id')"
          >
            {{ variant.getErrorMessage("production_type_id") }}
          </small>
        </div>
      </CCol>
    </CRow>
    <CRow class="align-items-center" v-if="variant.production_type_need_nation">
      <CCol md="4">
        <CSelect
          :value.sync="variant.production_type_nation_id"
          :options="productionTypeNationOptions"
          :disabled="productionTypeNationOptions.length === 1"
          :isValid="variant.isValid('production_type_nation_id')"
          :invalidFeedback="
            variant.getErrorMessage('production_type_nation_id')
          "
          custom
        >
          <template #label>
            <strong>{{
              $t(
                "producer.products.form.origin.sectionOrigin.variants.country.label"
              )
            }}</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LabelHelp from "../../../../common/form/LabelHelp";
import UiCInputRadioGroup from "../../../../common/form/UiCInputRadioGroup";
export default {
  name: "ProductFormVariantOrigin",
  components: {
    UiCInputRadioGroup,
    LabelHelp,
  },
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    markets: { type: Array, required: true },
    productionTypeNationOptions: Array,
    productionTypeOptions: Array,
    showName: { type: Boolean, default: false },
  },

  methods: {
    onVariantProductionTypeChange(variant, value) {
      this.$emit("onProductionTypeChange", variant, value);
    },
  },
  computed:{
    market(){
      if (this.variant.for_market==null){
        return null
      }
      const self = this
      const market_name = self.markets.reduce(function (map, obj) {
        if (obj.value == self.variant.for_market) {
          map = obj.label;
        }
        return map;
      }, "");
      return market_name
    },
  }
};
</script>
