<template>
  <aside class="ui-product__nav">
    <ul>
      <li>
        <a
          href="#prod-description"
          @click.prevent="scrollTo('#prod-description')"
          v-bind:class="{ 'text-danger': hasError('description') }"
          >{{ $t("producer.products.form.nav.description") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-logistics"
          @click.prevent="scrollTo('#prod-logistics')"
          v-bind:class="{ 'text-danger': hasError('logistics') }"
          >{{ $t("producer.products.form.nav.logistics") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-certifications"
          @click.prevent="scrollTo('#prod-certifications')"
          v-bind:class="{ 'text-danger': hasError('certifications') }"
          >{{ $t("producer.products.form.nav.certification") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-variants"
          @click.prevent="scrollTo('#prod-variants')"
          v-bind:class="{ 'text-danger': hasError('variants') }"
          >{{ $t("producer.products.form.nav.variants") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-price-weight"
          @click.prevent="scrollTo('#prod-price-weight')"
          v-bind:class="{ 'text-danger': hasError('price-weight') }"
          >{{ $t("producer.products.form.nav.price") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-origin"
          @click.prevent="scrollTo('#prod-origin')"
          v-bind:class="{ 'text-danger': hasError('origin') }"
          >{{ $t("producer.products.form.nav.origin") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-pictures"
          @click.prevent="scrollTo('#prod-pictures')"
          v-bind:class="{ 'text-danger': hasError('pictures') }"
          >{{ $t("producer.products.form.nav.photo") }}</a
        >
      </li>
      <li>
        <a
          href="#prod-availability"
          @click.prevent="scrollTo('#prod-availability')"
          v-bind:class="{ 'text-danger': hasError('availability') }"
          >{{ $t("producer.products.form.nav.availability") }}</a
        >
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "ProductFormNav",

  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    scrollTo(id) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
      });
    },

    hasError(sectionName) {
      const section = this.errors.find((e) => e.section === sectionName);
      return section ? section.error : false;
    },
  },
};
</script>
