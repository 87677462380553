<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }} <span v-if="market" class="small" style="color:black">[{{market}}]</span>
    </div>
    <CRow class="align-items-center">
      <CCol sm lg="4" xl="3">
        <CInput
          v-model="variant.last_min_n"
          :isValid="variant.isValid('last_min_n')"
          :invalidFeedback="variant.getErrorMessage('last_min_n')"
        >
          <template #label>
            <strong>{{
              $t("producer.products.form.origin.sectionPerishability.duration")
            }}</strong>
            <em class="text-danger">*</em>
          </template>
        </CInput>
      </CCol>
      <CCol sm lg="4" xl="3">
        <CSelect
          :value.sync="variant.last_min_t"
          :options="lastMinTOptions"
          :isValid="variant.isValid('last_min_t')"
          :invalidFeedback="variant.getErrorMessage('last_min_t')"
          custom
        >
          <template #label>
            <strong>{{
              $t("producer.products.form.origin.sectionPerishability.unit")
            }}</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "ProductFormVariantPerishability",
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    markets: { type: Array, required: true },
    lastMinTOptions: { type: Array, required: true },
    showName: { type: Boolean, default: false },
  },
  computed:{
    market(){
      if (this.variant.for_market==null){
        return null
      }
      const self = this
      const market_name = self.markets.reduce(function (map, obj) {
        if (obj.value == self.variant.for_market) {
          map = obj.label;
        }
        return map;
      }, "");
      return market_name
    },
  }
};
</script>
