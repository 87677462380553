<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }} <span v-if="market" class="small" style="color:black">[{{market}}]</span>
    </div>
    <CRow class="align-items-end">
      <CCol sm="6" md="5" lg>
        <CInput
          v-model="variant.price"
          :append="suffix"
          :isValid="variant.isValid('price')"
          :invalidFeedback="variant.getErrorMessage('price')"
        >
          <template #label>
            <label>
              <strong>{{
                $t("producer.products.form.Price.price.variants.sellingPrice")
              }}</strong>
              <em class="text-danger">*</em>
            </label>
          </template>
        </CInput>
      </CCol>
      <CCol sm="6" md="5" lg>
        <CInput
          v-model="variant.price_sugg"
          :append="suffix"
          :isValid="variant.isValid('price_sugg')"
          :invalidFeedback="variant.getErrorMessage('price_sugg')"
        >
          <template #label>
            <LabelHelp
              :required="false"
              :header="
                $t(
                  'producer.products.form.Price.price.variants.recommendedPrice'
                )
              "
              :content="
                $t(
                  'producer.products.form.Price.price.variants.recommendedPrice.description'
                )
              "
            >
              {{
                $t(
                  "producer.products.form.Price.price.variants.recommendedPrice"
                )
              }}
            </LabelHelp>
          </template>
        </CInput>
      </CCol>
      <CCol sm="6" md="5" lg>
        <CSelect
          :value.sync="variant.tax_rate_id"
          :options="taxOptions"
          :disabled="taxOptions.length === 0"
          :isValid="variant.isValid('tax_rate_id')"
          :invalidFeedback="variant.getErrorMessage('tax_rate_id')"
          custom
        >
          <template #label>
            <strong>{{
              $t("producer.products.form.Price.price.variants.rate")
            }}</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
      <CCol sm="6" md="5" lg v-if="showWeightFields">
        <CInput
          v-model="variant.price_len"
          :isValid="variant.isValid('price_len')"
          :invalidFeedback="variant.getErrorMessage('price_len')"
        >
          <template #label>
            <strong>{{
              $t("producer.products.form.Price.price.variants.weightPerPrice")
            }}</strong>
            <em class="text-danger">*</em>
          </template>
        </CInput>
      </CCol>
      <CCol sm="6" md="5" lg v-if="showWeightFields">
        <CSelect
          :value.sync="variant.price_unit"
          :options="priceUnitOptions"
          :isValid="variant.isValid('price_unit')"
          :invalidFeedback="variant.getErrorMessage('price_unit')"
          custom
        >
          <template #label>
            <strong>{{
              $t("producer.products.form.Price.price.variants.unit")
            }}</strong>
            <em class="text-danger">*</em>
          </template>
        </CSelect>
      </CCol>
      <CCol sm="6" md="5" lg v-if="showWeightFields">
        <div class="form-group">
          <CInputCheckbox
            :label="$t('producer.products.form.Price.price.variants.circa')"
            :checked.sync="variant.price_len_mol"
            :isValid="variant.isValid('price_len_mol')"
            :invalidFeedback="variant.getErrorMessage('price_len_mol')"
            custom
          />
          <CInputCheckbox
            :label="
              $t('producer.products.form.Price.price.variants.pricePerWeight')
            "
            :checked.sync="variant.pw"
            :isValid="variant.isValid('pw')"
            :invalidFeedback="variant.getErrorMessage('pw')"
            custom
          />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LabelHelp from "../../../../common/form/LabelHelp";
export default {
  name: "ProductFormVariantPrice",
  components: {
    LabelHelp,
  },
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    taxOptions: { type: Array, required: true },
    markets: { type: Array, required: true },
    priceUnitOptions: { type: Array, required: true },
    suffix: { type: String, default: undefined },
    showWeightFields: { type: Boolean, default: true },
    showName: { type: Boolean, default: false },
  },
  computed:{
    market(){
      if (this.variant.for_market==null){
        return null
      }
      const self = this
      const market_name = self.markets.reduce(function (map, obj) {
        if (obj.value == self.variant.for_market) {
          map = obj.label;
        }
        return map;
      }, "");
      return market_name
    },
  }
};
</script>
