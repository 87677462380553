<template>
  <CForm @submit.prevent="onSubmit">
    <CRow class="align-items-end">
      <CCol md="5">
        <CInput
          :label="$t('producer.products.filters.search.title')"
          :placeholder="$t('producer.products.filters.search.placeholder')"
          v-model="form.q"
        >
          <template #prepend-content>
            <font-awesome-icon icon="search" />
          </template>
        </CInput>
      </CCol>
      <CCol md>
        <UiSelect
          :label="$t('producer.products.filters.category.title')"
          :value.sync="form.category_id"
          :options="options.category"
          custom
        />
      </CCol>
      <CCol md>
        <UiSelect
          :label="$t('producer.products.filters.line.title')"
          :value.sync="form.logistic_type_id"
          :options="options.logistic_type"
          custom
        />
      </CCol>
      <CCol md>
        <UiSelect
          :label="$t('producer.products.filters.onsale.title')"
          :value.sync="form.sell_id"
          :options="options.sell"
          custom
        />
      </CCol>
      <CCol md>
        <div class="form-group">
          <CButton
            color="primary"
            variant="outline"
            type="submit"
            :block="true"
          >
            {{ $t('producer.products.filters.btn.title') }}
          </CButton>
        </div>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import {
  getProducerCategoryTree,
  getProducerTypeProducts,
} from "../../../helpers/options";
import UiSelect from "../../../common/form/UiSelect";

export default {
  name: "ProductsTableListFilters",
  components: {
    UiSelect,
  },
  data() {
    return {
      form: {
        q: "",
        category_id: "",
        logistic_type_id: "",
        sell_id: "",
      },
      options: {
        category: [],
        logistic_type: [],
        sell: [
          { value: null, label: "- "+this.$t('producer.products.filters.onsale.options.all')+" -" },
          { value: 1, label: this.$t('producer.products.filters.onsale.options.disabled') },
          { value: 2, label: this.$t('producer.products.filters.onsale.options.enabled') },
          { value: 3, label: this.$t('producer.products.filters.onsale.options.approving') },
        ],
      },
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  async mounted() {
    const lang = this.$i18n.locale;
    const roleId = this.$store.state.role.id;
    try {
      this.options.category = [
        { value: null, label: "- "+this.$t('producer.products.filters.onsale.options.all')+" -" },
        ...(await getProducerCategoryTree({
          roleId,
          lang,
        })),
      ];

      this.options.logistic_type = [
        { value: null, label: "- "+this.$t('producer.products.filters.onsale.options.all')+" -" },
        ...(await getProducerTypeProducts({
          roleId,
          lang,
        })),
      ];
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.form);
    },

    onReset() {
      this.form = {
        q: "",
        category_id: "",
        logistic_type_id: "",
        sell_id: "",
      };
      this.$emit("onReset", this.form);
    },
  },
};
</script>
