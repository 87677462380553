<template>
  <div class="d-flex">
    <CButton color="primary" variant="outline" @click="onClick" :title="$t('producer.products.table.actions.clone')">
      <font-awesome-icon icon="copy" />
    </CButton>
  </div>
</template>

<script>
export default {
  name: "ProductTableVariantActions",
  data() {
    return {
      data: {},
    };
  },
  methods: {
    onClick() {
      this.$router.push({
        name: "RoleProductsCreate",
        params: { id: this.$store.state.role.id },
        query: { from_product_id: this.data.productproducer.id },
      });
    },
  },
};
</script>
