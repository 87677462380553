<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }} <span v-if="market" class="small" style="color:black">[{{market}}]</span>
    </div>
    <CRow class="align-items-center">
      <CCol sm="6" lg="5" xl="4">
        <CInput
          v-model="variant.pw_min"
          :isValid="variant.isValid('pw_min')"
          :invalidFeedback="variant.getErrorMessage('pw_min')"
          :append="suffix"
        >
          <template #label>
            <strong>{{
              $t("producer.products.form.Price.minQtaVariants")
            }}</strong>
          </template>
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "ProductFormVariantMinQuantity",
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    markets: { type: Array, required: true },
    suffix: { type: String, default: undefined },
    showName: { type: Boolean, default: false },
  },
  computed:{
    market(){
      if (this.variant.for_market==null){
        return null
      }
      const self = this
      const market_name = self.markets.reduce(function (map, obj) {
        if (obj.value == self.variant.for_market) {
          map = obj.label;
        }
        return map;
      }, "");
      return market_name
    },
  }
};
</script>
