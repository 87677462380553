<template>
  <div>
    <div
      v-for="variant in variants"
      :key="variant.id"
      class="border-bottom mb-2"
    >
      <CRow>
        <CCol md="3" xl="4">
          <CInput
            required
            v-model="variant.translations[lang].name"
            :isValid="variant.isValid(`translations.${lang}.name`)"
            :invalidFeedback="
              variant.getErrorMessage(`translations.${lang}.name`)
            "
          >
            <template #label>
              <LabelHelp
                :required="true"
                :header="$t('producer.products.form.Variants.name.help.title')"
                :content="$t('producer.products.form.Variants.name.help.text')"
              >
                {{ $t('producer.products.form.Variants.name.title') }}
              </LabelHelp>
            </template>
          </CInput>
        </CCol>
        <CCol sm="4" md="2">
          <CInput
            v-model="variant.producer_code"
            :isValid="variant.isValid('producer_code')"
            :invalidFeedback="variant.getErrorMessage('producer_code')"
          >
            <template #label>
              <LabelHelp
                :required="false"
                :header="$t('producer.products.form.Variants.sku.help.title')"
                :content="$t('producer.products.form.Variants.sku.help.text')"
              >
                {{ $t('producer.products.form.Variants.sku.title') }}
              </LabelHelp>
            </template>
          </CInput>
        </CCol>
        <CCol sm="4" md="2">
          <CSelect
            :value.sync="variant.for_market"
            :options="markets"
            :isValid="variant.isValid('for_market')"
            :invalidFeedback="
              variant.getErrorMessage('for_market')
            "
            custom
          >
            <template #label>
              <LabelHelp
                :required="false"
                :header="$t('producer.products.form.Variants.for_market.help.title')"
                :content="$t('producer.products.form.Variants.for_market.help.text')"
              >
                {{ $t('producer.products.form.Variants.for_market.title') }}
              </LabelHelp>
            </template>
          </CSelect>
        </CCol>
        <CCol sm="auto">
          <div class="form-group">
            <strong>{{ $t('producer.products.form.Variants.enabled.title') }}</strong>
            <div class="mt-2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="variant.is_active"
              />
            </div>
          </div>
        </CCol>
        <CCol sm="auto" class="align-self-center ml-auto">
          <CButton
            variant="outline"
            color="primary"
            @click="onCopyVariant(variant)"
            :title="$t('producer.products.form.Variants.btnCopyVariant')"
            ><font-awesome-icon icon="copy"
          /></CButton>
          <CButton
            variant="outline"
            color="danger"
            v-if="variant.can_be_deleted && canDelete"
            @click="onDeleteVariant(variant)"
            :title="$t('producer.products.form.Variants.btnDeleteVariant')"
            ><font-awesome-icon icon="trash-alt"
          /></CButton>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import LabelHelp from "../../../../common/form/LabelHelp";
import ProductVariant from "../../../../helpers/ProductVariant";
export default {
  name: "ProductFormVariants",
  components: {
    LabelHelp,
  },
  props: {
    variants: { type: Array, required: true },
    markets: { type: Array, required: true },
    lang: { type: String, required: true },
    canDelete: { type: Boolean, default: true },
  },

  methods: {
    onDeleteVariant(variant) {
      this.$emit("onDelete", variant);
    },
    onCopyVariant(variant){
      let newvariant = variant.snapshot()
      newvariant.id=null
      newvariant = new ProductVariant(newvariant);
      this.variants.push(newvariant)
    }
  },
};
</script>
