var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'border-bottom mb-2': _vm.showName }},[(_vm.showName)?_c('div',{staticClass:"ui-type-heading text-primary"},[_vm._v(" "+_vm._s(_vm.variant.translations[_vm.lang].name)+" "),(_vm.market)?_c('span',{staticClass:"small",staticStyle:{"color":"black"}},[_vm._v("["+_vm._s(_vm.market)+"]")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('UiCInputRadioGroup',{attrs:{"options":_vm.options.valid_limited,"checked":_vm.variant.valid_limited,"custom":"","inline":""},on:{"update:checked":function($event){return _vm.$set(_vm.variant, "valid_limited", $event)}}})],1),(_vm.variant.valid_limited === 'limited')?_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('LabelHelp',{attrs:{"header":_vm.$t(
            'producer.products.form.availability.salesPeriodSection.variants.fromHeader'
          ),"content":_vm.$t(
            'producer.products.form.availability.salesPeriodSection.variants.fromDescription'
          )}},[_vm._v(" "+_vm._s(_vm.$t( "producer.products.form.availability.salesPeriodSection.variants.from" ))+" ")]),_c('UiDatePicker',{attrs:{"date":_vm.variant.valid_from,"isValid":_vm.variant.isValid('valid_from'),"invalidFeedback":_vm.variant.getErrorMessage('valid_from')},on:{"update:date":function($event){return _vm.$set(_vm.variant, "valid_from", $event)}}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('LabelHelp',{attrs:{"header":_vm.$t(
            'producer.products.form.availability.salesPeriodSection.variants.untilHeader'
          ),"content":_vm.$t(
            'producer.products.form.availability.salesPeriodSection.variants.untilDescription'
          )}},[_vm._v(" "+_vm._s(_vm.$t( "producer.products.form.availability.salesPeriodSection.variants.until" ))+" ")]),_c('UiDatePicker',{attrs:{"date":_vm.variant.valid_to,"isValid":_vm.variant.isValid('valid_to'),"invalidFeedback":_vm.variant.getErrorMessage('valid_to')},on:{"update:date":function($event){return _vm.$set(_vm.variant, "valid_to", $event)}}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }