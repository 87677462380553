<template>
  <div :class="{ 'border-bottom mb-2': showName }">
    <div v-if="showName" class="ui-type-heading text-primary">
      {{ variant.translations[lang].name }} <span v-if="market" class="small" style="color:black">[{{market}}]</span>
    </div>
    <div class="form-group">
      <UiCInputRadioGroup
        :options="options.valid_limited"
        :checked.sync="variant.valid_limited"
        custom
        inline
      />
    </div>
    <CRow v-if="variant.valid_limited === 'limited'">
      <CCol md="4">
        <LabelHelp
          :header="
            $t(
              'producer.products.form.availability.salesPeriodSection.variants.fromHeader'
            )
          "
          :content="
            $t(
              'producer.products.form.availability.salesPeriodSection.variants.fromDescription'
            )
          "
        >
          {{
            $t(
              "producer.products.form.availability.salesPeriodSection.variants.from"
            )
          }}
        </LabelHelp>

        <UiDatePicker
          :date.sync="variant.valid_from"
          :isValid="variant.isValid('valid_from')"
          :invalidFeedback="variant.getErrorMessage('valid_from')"
        />
      </CCol>
      <CCol md="4">
        <LabelHelp
          :header="
            $t(
              'producer.products.form.availability.salesPeriodSection.variants.untilHeader'
            )
          "
          :content="
            $t(
              'producer.products.form.availability.salesPeriodSection.variants.untilDescription'
            )
          "
        >
          {{
            $t(
              "producer.products.form.availability.salesPeriodSection.variants.until"
            )
          }}
        </LabelHelp>
        <UiDatePicker
          :date.sync="variant.valid_to"
          :isValid="variant.isValid('valid_to')"
          :invalidFeedback="variant.getErrorMessage('valid_to')"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UiCInputRadioGroup from "../../../../common/form/UiCInputRadioGroup";
import UiDatePicker from "../../../../common/form/UiDatePicker";
import { getValidLimitedOptions } from "../../../../helpers/options";
import LabelHelp from "../../../../common/form/LabelHelp";

export default {
  name: "ProductFormVariantSellTime",
  components: {
    UiCInputRadioGroup,
    UiDatePicker,
    LabelHelp,
  },
  props: {
    variant: { type: Object, required: true },
    lang: { type: String, required: true },
    markets: { type: Array, required: true },
    showName: { type: Boolean, default: false },
  },
  data() {
    return {
      options: {
        valid_limited: getValidLimitedOptions(this.$i18n),
      },
    };
  },
  computed:{
    market(){
      if (this.variant.for_market==null){
        return null
      }
      const self = this
      const market_name = self.markets.reduce(function (map, obj) {
        if (obj.value == self.variant.for_market) {
          map = obj.label;
        }
        return map;
      }, "");
      return market_name
    },
  }
};
</script>
